import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelQuery } from 'app/app-services/model.service';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AdminService, HandleErrorsService, ModelService, PrintingService } from '../app-services';
import { Admin } from '../models/admin.model';
import { Model } from '../models/model.model';
import { ExtendedPrinting, FilterQuery } from '../models/printing.model';
import { SearchEvent } from 'app/models/search-event.model';

@Component({
  selector: 'super-admin-printing-log',
  templateUrl: './super-admin-printing-log.component.html',
  styleUrls: ['./super-admin-printing-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperAdminPrintingLogComponent implements OnInit {
  printings = new ReplaySubject<EntityList<ExtendedPrinting>>(1);
  adminList = new ReplaySubject<Admin[]>(1);
  modelList = new ReplaySubject<Model[]>(1);
  offset$ = of(0);
  searchedDates = { endDate: null, startDate: null };
  searchedModels$: Observable<Model[]>;
  searchedAdmins$: Observable<Admin[]>;

  constructor(
    protected handleErrors: HandleErrorsService,
    protected printingService: PrintingService,
    protected adminsService: AdminService,
    protected modelService: ModelService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {}

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).map(k => {
            switch (k) {
              case 'modelIds':
                query['modelIds'] = queryParams['modelIds'].split(',');
                this.searchedModels$ = this.modelService.getModelList({ showParents: true, ids: [queryParams['modelIds']] }).pipe(
                  map(response => response.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );
                break;
              case 'adminIds':
                query['adminIds'] = queryParams['adminIds'].split(',');
                this.searchedAdmins$ = this.adminsService.getAdminList({ ids: query['adminIds'] }).pipe(
                  map(response => response.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );
                break;

              default:
                query[k] = queryParams[k];
                this.searchedDates[k] = queryParams[k];
                break;
            }
          });

          return this.printingService.getPrintingList(query);
        }),
      )
      .subscribe({
        next: response => {
          this.printings.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchAdminList(event: { like: string; exclude: number[] }) {
    this.adminsService
      .getAdminList(event)
      .pipe(distinctUntilChanged())
      .subscribe({
        next: res => {
          this.adminList.next(res.data.listing);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchModelList(query: ModelQuery) {
    this.modelService.getModelList(query).subscribe({
      next: result => {
        this.modelList.next(result.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  filterPrintings(queryParams: FilterQuery) {
    this.router.navigate(['super-admin-printing-log'], { queryParams });
  }

  pageNavigation(event: SearchEvent) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['super-admin-printing-log', event.offset + 1], { queryParams });
  }
}
