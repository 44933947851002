import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormDataInterceptor } from './app-services/formdata-interceptor.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  AdminService,
  AuthService,
  BannerService,
  AuthStateService,
  HandleErrorsService,
  InterceptorService,
  ModelAdminService,
  ModelService,
  PrintableDataService,
  PrintingService,
  SuperAdminService,
  UsersService,
  MagicTranslateService,
} from './app-services';

import {
  AdminDetailsResolver,
  CurrentAdminDataResolver,
  CurrentModelAdminResolver,
  SuperAdminResolver,
  UserDetailsResolver,
} from './resolvers';

import {
  AntiGuard,
  DialogCloseGuard,
  HasAccessToPrintingsGuard,
  IsAdminGuard,
  IsAdminOrSuperAdminGuard,
  IsLoggedGuard,
  IsModelAdminGuard,
  IsSuperAdminGuard,
} from './guards';

import { appRoutes } from './app.routes';

import { AddAdminDialogComponent } from './add-admin-dialog/add-admin-dialog.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AddBrandDialogComponent } from './add-brand-dialog/add-brand-dialog.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { AddModelAdminDialogComponent } from './add-model-admin-dialog/add-model-admin-dialog.component';
import { AddModelAdminComponent } from './add-model-admin/add-model-admin.component';
import { AddModelDialogComponent } from './add-model-dialog/add-model-dialog.component';
import { AddModelComponent } from './add-model/add-model.component';
import { AddPrintableDataDialogComponent } from './add-printable-data-dialog/add-printable-data-dialog.component';
import { AddPrintableDataComponent } from './add-printable-data/add-printable-data.component';
import { AddSerieDialogComponent } from './add-serie-dialog/add-serie-dialog.component';
import { AddSerieComponent } from './add-serie/add-serie.component';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AdminDetailProfileFormComponent } from './admin-detail-profile-form/admin-detail-profile-form.component';
import { AdminDetailsComponent } from './admin-details/admin-details.component';
import { AdminFormComponent } from './admin-form/admin-form.component';
import { AdminListComponent } from './admin-list/admin-list.component';
import { AdminManagementFilterComponent } from './admin-management-filter/admin-management-filter.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AdminProfilTabComponent } from './admin-profil-tab/admin-profil-tab.component';
import { AdminProfileFormComponent } from './admin-profile-form/admin-profile-form.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminUsersFilterComponent } from './admin-users-filter/admin-users-filter.component';
import { AdminUsersListTabComponent } from './admin-users-list-tab/admin-users-list-tab.component';
import { AdminUsersListComponent } from './admin-users-list/admin-users-list.component';
import { AdminUsersManagementComponent } from './admin-users-management/admin-users-management.component';
import { AppComponent } from './app.component';
import { BrandFilterComponent } from './brand-filter/brand-filter.component';
import { BrandFormComponent } from './brand-form/brand-form.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { BrandManagementComponent } from './brand-management/brand-management.component';
import { BrandSerieModelManagementComponent } from './brand-serie-model-management/brand-serie-model-management.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmDeletionDialogComponent } from './confirm-deletion-dialog/confirm-deletion-dialog.component';
import { DeleteAdminAndTransferUsersComponent } from './delete-admin-and-transfer-users/delete-admin-and-transfer-users.component';
import { DeleteBrandDialogDumbComponent } from './delete-brand-dialog-dumb/delete-brand-dialog-dumb.component';
import { DeleteBrandDialogComponent } from './delete-brand-dialog/delete-brand-dialog.component';
import { DeleteSerieDialogDumbComponent } from './delete-serie-dialog-dumb/delete-serie-dialog-dumb.component';
import { DeleteSerieDialogComponent } from './delete-serie-dialog/delete-serie-dialog.component';
import { EditBrandDialogComponent } from './edit-brand-dialog/edit-brand-dialog.component';
import { EditBrandComponent } from './edit-brand/edit-brand.component';
import { EditModelAdminDialogComponent } from './edit-model-admin-dialog/edit-model-admin-dialog.component';
import { EditModelAdminComponent } from './edit-model-admin/edit-model-admin.component';
import { EditModelDialogComponent } from './edit-model-dialog/edit-model-dialog.component';
import { EditModelComponent } from './edit-model/edit-model.component';
import { EditSerieDialogComponent } from './edit-serie-dialog/edit-serie-dialog.component';
import { EditSerieComponent } from './edit-serie/edit-serie.component';
import { EditableUserProfileComponent } from './editable-user-profile/editable-user-profile.component';
import { GlobalUsersFilterComponent } from './global-users-filter/global-users-filter.component';
import { GlobalUsersManagementComponent } from './global-users-management/global-users-management.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ModelAdminFormComponent } from './model-admin-form/model-admin-form.component';
import { ModelAdminListComponent } from './model-admin-list/model-admin-list.component';
import { ModelAdminManagementComponent } from './model-admin-management/model-admin-management.component';
import { ModelAdminProfileFormComponent } from './model-admin-profile-form/model-admin-profile-form.component';
import { ModelAdminProfileComponent } from './model-admin-profile/model-admin-profile.component';
import { ModelAdminToolbarComponent } from './model-admin-toolbar/model-admin-toolbar.component';
import { ModelFilterComponent } from './model-filter/model-filter.component';
import { ModelFormComponent } from './model-form/model-form.component';
import { ModelListComponent } from './model-list/model-list.component';
import { ModelManagementComponent } from './model-management/model-management.component';
import { ModelViewerComponent } from './model-viewer/model-viewer.component';
import { MpFooterComponent } from './mp-footer/mp-footer.component';
import { MpHeaderComponent } from './mp-header/mp-header.component';
import { PrintableDataAddDumbComponent } from './printable-data-add-dumb/printable-data-add-dumb.component';
import { PrintableDataFilterComponent } from './printable-data-filter/printable-data-filter.component';
import { PrintableDataListComponent } from './printable-data-list/printable-data-list.component';
import { PrintableDataManagementComponent } from './printable-data-management/printable-data-management.component';
import { PrintableDataPreviewDialogComponent } from './printable-data-preview-dialog/printable-data-preview-dialog.component';
import { PrintableDataPreviewComponent } from './printable-data-preview/printable-data-preview.component';
import { PrintingListComponent } from './printing-list/printing-list.component';
import { PrintingVisualisationDialogComponent } from './printing-visualisation-dialog/printing-visualisation-dialog.component';
import { PrintingVisualisationViewComponent } from './printing-visualisation-view/printing-visualisation-view.component';
import { PrintingVisualisationComponent } from './printing-visualisation/printing-visualisation.component';
import { ReadOnlyUserProfileComponent } from './read-only-user-profile/read-only-user-profile.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SerieFilterComponent } from './serie-filter/serie-filter.component';
import { SerieFormComponent } from './serie-form/serie-form.component';
import { SerieListComponent } from './serie-list/serie-list.component';
import { SerieManagementComponent } from './serie-management/serie-management.component';
import { SuperAdminPrintingFilterComponent } from './super-admin-printing-filter/super-admin-printing-filter.component';
import { SuperAdminPrintingLogComponent } from './super-admin-printing-log/super-admin-printing-log.component';
import { SuperAdminProfileFormComponent } from './super-admin-profile-form/super-admin-profile-form.component';
import { SuperAdminProfileComponent } from './super-admin-profile/super-admin-profile.component';
import { SuperAdminUserListComponent } from './super-admin-user-list/super-admin-user-list.component';
import { TransferUsersFormComponent } from './transfer-users-form/transfer-users-form.component';
import { UserDetailsTabsComponent } from './user-details-tabs/user-details-tabs.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserPrintingFilterComponent } from './user-printing-filter/user-printing-filter.component';
import { UserPrintingLogComponent } from './user-printing-log/user-printing-log.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { AdminCreditComponent } from './admin-credit/admin-credit.component';

import { AdminPrintingFilterComponent } from './admin-printing-filter/admin-printing-filter.component';
import { AdminPrintingLogComponent } from './admin-printing-log/admin-printing-log.component';
import { CropperComponent } from './cropper/cropper.component';
import { DeleteModelDialogComponent } from './delete-model-dialog/delete-model-dialog.component';
import { DeletePlotterDialogComponent } from './delete-plotter-dialog/delete-plotter-dialog.component';
import { DeletePrintableDataDialogComponent } from './delete-printable-data-dialog/delete-printable-data-dialog.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrintableDataEditDumbComponent } from './printable-data-edit-dumb/printable-data-edit-dumb.component';
import { RequestPasswordResetFormComponent } from './request-password-reset-form/request-password-reset-form.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatChipsModule } from '@angular/material/chips';
export function getWindow() {
  return window;
}
import { NgPipesModule } from 'ngx-pipes';
import { AppMaterialModule } from './material.module';
import { BannerFormComponent } from './banner-form/banner-form.component';
import { BannerProfilTabComponent } from './banner-profil-tab/banner-profil-tab.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProfileCreditComponent } from './profile-credit/profile-credit.component';
import { SuperAdminCreditLogComponent } from './super-admin-credit-log/super-admin-credit-log.component';
import { SuperAdminCreditLogFilterComponent } from './super-admin-credit-log-filter/super-admin-credit-log-filter.component';
import { SuperAdminCreditLogListComponent } from './super-admin-credit-log-list/super-admin-credit-log-list.component';
import { LoggingService } from './app-services/logging.service';
import { PlotterService } from './app-services/plotter.service';
import { PaymentDocumentService } from './app-services/payment-document.service';
import { UserCreditFormComponent } from './user-credit-form/user-credit-form.component';
import { AdminCreditLogListComponent } from './admin-credit-log-list/admin-credit-log-list.component';
import { AdminCreditLogFilterComponent } from './admin-credit-log-filter/admin-credit-log-filter.component';
import { AdminCreditLogComponent } from './admin-credit-log/admin-credit-log.component';
import { AdminCreditFormComponent } from './admin-credit-form/admin-credit-form.component';
import { SuperAdminPaymentDocumentLogComponent } from './super-admin-payment-document-log/super-admin-payment-document-log.component';
import { EPaymentFilterComponent } from './super-admin-payment-document-log-filter/super-admin-payment-document-log-filter.component';
import { EPaymentListComponent } from './super-admin-payment-document-log-list/super-admin-payment-document-log-list.component';
import { ConfirmCancelUnpaidPaymentDocumentComponent } from './confirm-cancel-unpaid-payment-document/confirm-cancel-unpaid-payment-document.component';
import { AddPaymentDocumentComponent } from './add-payment-document/add-payment-document.component';
import { AddPaymentDocumentDialogComponent } from './add-payment-document-dialog/add-payment-document-dialog.component';
import { PaymentDocumentFormComponent } from './payment-document-form/payment-document-form.component';
import { AdminPaymentDocumentLogFilterComponent } from './admin-payment-document-log-filter/admin-payment-document-log-filter.component';
import { AdminPaymentDocumentLogListComponent } from './admin-payment-document-log-list/admin-payment-document-log-list.component';
import { AdminPaymentDocumentLogComponent } from './admin-payment-document-log/admin-payment-document-log.component';
import { StripePaymentInterfaceComponent } from './stripe-payment-interface/stripe-payment-interface.component';
import { SuccessDocumentPaymentComponent } from './success-document-payment/success-document-payment.component';
import { SuccessDocumentPaymentDialogComponent } from './success-document-payment-dialog/success-document-payment-dialog.component';
import { StripePaymentInterfaceDialogComponent } from './stripe-payment-interface-dialog/stripe-payment-interface-dialog.component';
import { PlotterLogComponent } from './plotter-log/plotter-log.component';
import { PlotterLogListComponent } from './plotter-log-list/plotter-log-list.component';
import { PlotterLogFilterComponent } from './plotter-log-filter/plotter-log-filter.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MagicCutSpinnerComponent } from './magic-cut-spinner/magic-cut-spinner.component';
import { PlotterFormComponent } from './plotter-form/plotter-form.component';
import { AddPlotterComponent } from './add-plotter/add-plotter.component';
import { AddPlotterDialogComponent } from './add-plotter-dialog/add-plotter-dialog.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MpHeaderComponent,
    MpFooterComponent,
    HomeComponent,
    LoginComponent,
    LoginFormComponent,
    MainComponent,
    HeaderMenuComponent,
    AdminDetailsComponent,
    AdminManagementComponent,
    AdminCreditLogFilterComponent,
    AdminCreditLogListComponent,
    UserCreditFormComponent,
    AdminListComponent,
    DeleteAdminAndTransferUsersComponent,
    TransferUsersFormComponent,
    AdminProfilTabComponent,
    AdminDetailProfileFormComponent,
    AddAdminDialogComponent,
    AddAdminComponent,
    AdminFormComponent,
    AdminManagementFilterComponent,
    GlobalUsersManagementComponent,
    PrintingVisualisationComponent,
    PrintingVisualisationDialogComponent,
    AdminUsersListTabComponent,
    AdminUsersListComponent,
    UserDetailsComponent,
    UserDetailsTabsComponent,
    UserProfileComponent,
    ReadOnlyUserProfileComponent,
    UserFormComponent,
    EditableUserProfileComponent,
    SuperAdminUserListComponent,
    ConfirmDeletionDialogComponent,
    UserPrintingLogComponent,
    PrintingListComponent,
    PrintingVisualisationViewComponent,
    AdminUsersManagementComponent,
    UserPrintingFilterComponent,
    AddUserComponent,
    AddUserDialogComponent,
    GlobalUsersFilterComponent,
    AdminUsersFilterComponent,
    SuperAdminPrintingLogComponent,
    SuperAdminPrintingFilterComponent,
    ModelAdminManagementComponent,
    ModelAdminListComponent,
    ModelAdminToolbarComponent,
    AddModelAdminComponent,
    AddModelAdminDialogComponent,
    ModelAdminFormComponent,
    EditModelAdminComponent,
    EditModelAdminDialogComponent,
    SuperAdminProfileComponent,
    SuperAdminProfileFormComponent,
    AdminProfileComponent,
    AdminProfileFormComponent,
    AdminPrintingLogComponent,
    ModelAdminProfileComponent,
    ModelAdminProfileFormComponent,
    ModelManagementComponent,
    ModelListComponent,
    ModelFilterComponent,
    ChangePasswordComponent,
    ChangePasswordDialogComponent,
    ChangePasswordFormComponent,
    ResetPasswordFormComponent,
    ResetPasswordComponent,
    AddModelComponent,
    ModelViewerComponent,
    BrandSerieModelManagementComponent,
    SerieManagementComponent,
    SerieListComponent,
    SerieFilterComponent,
    AddSerieComponent,
    AddSerieDialogComponent,
    SerieFormComponent,
    EditSerieComponent,
    EditSerieDialogComponent,
    DeleteSerieDialogComponent,
    DeleteSerieDialogDumbComponent,
    AddModelDialogComponent,
    ModelFormComponent,
    EditModelComponent,
    EditModelDialogComponent,
    PrintableDataManagementComponent,
    PrintableDataListComponent,
    PrintableDataFilterComponent,
    AddPrintableDataComponent,
    AddPrintableDataDialogComponent,
    BrandManagementComponent,
    BrandListComponent,
    EditBrandComponent,
    EditBrandDialogComponent,
    BrandFormComponent,
    DeleteBrandDialogComponent,
    BrandFilterComponent,
    AddBrandComponent,
    AddBrandDialogComponent,
    DeleteBrandDialogDumbComponent,
    PrintableDataAddDumbComponent,
    PrintableDataPreviewComponent,
    PrintableDataPreviewDialogComponent,
    DeleteModelDialogComponent,
    AdminPrintingLogComponent,
    AdminPrintingFilterComponent,
    CropperComponent,
    RequestPasswordResetComponent,
    RequestPasswordResetFormComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    PrintableDataEditDumbComponent,
    DeletePrintableDataDialogComponent,
    BannerFormComponent,
    BannerProfilTabComponent,
    ImageCropperComponent,
    ProfileCreditComponent,
    AddressFormComponent,
    SuperAdminCreditLogComponent,
    SuperAdminCreditLogFilterComponent,
    SuperAdminCreditLogListComponent,
    AdminCreditComponent,
    AdminCreditLogComponent,
    AdminCreditFormComponent,
    SuperAdminPaymentDocumentLogComponent,
    EPaymentFilterComponent,
    EPaymentListComponent,
    AddPaymentDocumentComponent,
    AddPaymentDocumentDialogComponent,
    PaymentDocumentFormComponent,
    AdminPaymentDocumentLogFilterComponent,
    AdminPaymentDocumentLogListComponent,
    AdminPaymentDocumentLogComponent,
    StripePaymentInterfaceComponent,
    SuccessDocumentPaymentComponent,
    SuccessDocumentPaymentDialogComponent,
    StripePaymentInterfaceDialogComponent,
    CountryNamePipe,
    ConfirmCancelUnpaidPaymentDocumentComponent,
    MagicCutSpinnerComponent,
    PlotterLogComponent,
    PlotterLogListComponent,
    PlotterLogFilterComponent,
    DeletePlotterDialogComponent,
    PlotterFormComponent,
    AddPlotterComponent,
    AddPlotterDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {}),
    MatChipsModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    NgPipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: 'Window', useFactory: getWindow },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FormDataInterceptor,
      multi: true,
    },
    UsersService,
    AdminService,
    SuperAdminService,
    PrintingService,
    ModelService,
    BannerService,
    AuthService,
    ModelAdminService,
    UserDetailsResolver,
    AdminDetailsResolver,
    CurrentAdminDataResolver,
    SuperAdminResolver,
    CurrentModelAdminResolver,
    DialogCloseGuard,
    IsAdminGuard,
    IsSuperAdminGuard,
    IsAdminOrSuperAdminGuard,
    IsLoggedGuard,
    AntiGuard,
    HasAccessToPrintingsGuard,
    IsModelAdminGuard,
    PrintableDataService,
    AuthStateService,
    HandleErrorsService,
    LoggingService,
    PaymentDocumentService,
    MagicTranslateService,
    PlotterService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
